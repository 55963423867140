/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    code: "code",
    pre: "pre",
    h2: "h2",
    div: "div",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "All Signatu React components have support for theming, allowing you to style the components\nusing your own colors and typography. Under the hood we use ", React.createElement(_components.a, {
    href: "https://github.com/cssinjs/react-jss"
  }, "react-jss"), "\nto dynamically create CSS classes and inject them into the DOM."), "\n", React.createElement(_components.p, null, "For this to work the Signatu components must be contained in a ", React.createElement(_components.code, null, "ThemeProvider"), " that will inject\nthe theme onto the components. Use the provided ", React.createElement(_components.code, null, "createTheme"), " function to create your theme. For example:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-javascript"
  }, "import { ThemeProvider, createTheme } from '@signatu/sdk/react/styles'\n\nconst myTheme = createTheme({\n    typography: {\n        fontFamily: 'Open Sans',\n        fontWeight: 300,\n    },\n    palette: {\n        primary: {\n            main: '#0b3297',\n            contrastText: '#fff',\n        },\n        secondary: {\n            main: '#15bad2',\n            contrastText: '#fff',\n        },\n    },\n})\n\nconst MyComponent = props => (\n    <ThemeProvider theme={myTheme}>\n        <ConsentButton />\n    </ThemeProvider>\n)\n")), "\n", React.createElement(_components.h2, {
    id: "supported-theme-settings",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#supported-theme-settings",
    "aria-label": "supported theme settings permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Supported theme settings"), "\n", React.createElement(_components.p, null, "The most relevant theming options for the consent components are:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "palette.primary.main"), ": the primary color used as background for e.g., default buttons, cookie banner."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "palette.secondary.main"), ": the secondary color used in combination with the ", React.createElement(_components.code, null, "primary"), " color."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "typography.fontFamily"), ": the default font."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "typography.fontWeight"), ": the default font weight."), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "typography.fontSize"), ": the default font size."), "\n"), "\n", React.createElement(_components.p, null, "Note that you can either use camelCase (e.g., ", React.createElement(_components.code, null, "fontSize"), ") or regular HTML names in quotes (e.g., ", React.createElement(_components.code, null, "'font-size'"), ") for the HTML properties."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
